exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-drops-tsx": () => import("./../../../src/pages/drops.tsx" /* webpackChunkName: "component---src-pages-drops-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lore-tsx": () => import("./../../../src/pages/lore.tsx" /* webpackChunkName: "component---src-pages-lore-tsx" */),
  "component---src-pages-mint-tsx": () => import("./../../../src/pages/mint.tsx" /* webpackChunkName: "component---src-pages-mint-tsx" */),
  "component---src-pages-ogs-tsx": () => import("./../../../src/pages/ogs.tsx" /* webpackChunkName: "component---src-pages-ogs-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

